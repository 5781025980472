import React, { useRef, useState } from "react";
import {
  Flex,
  Text,
  Heading,
  Card,
  Icon,
  View,
  Image,
  Button,
  Collection,
  Badge,
  TextField,
} from "@aws-amplify/ui-react";
import { useContext, useEffect } from "react";
import { PzPrimeContext } from "../Context";
import { DataStore } from "aws-amplify";
import {
  Project,
  Space,
  Organization,
  PriceSheet,
  PricingModel,
} from "../models";
import { useNavigate, useSearchParams } from "react-router-dom";
import Logger from "../utils/Logger";

const ProjectCollection = ({
  projectLoadCount = 10,
  projectList,
  loadPage,
  optionalFunction = null,
  isProjectLink = null,
}) => {
  Logger.debug("ProjectCollection", "Project list:", projectList);
  const {
    colorMode,
    setProjectName,
    setAddress,
    setLocation,
    setDistanceMatrixData,
    setDiscipline,
    discipline,
    setConstructionType,
    setProjectArea,
    setNumberOfBuildings,
    setBuildingArea,
    setSelectedMarkets,
    setSelectedPackage,
    setProjectId,
    setAuthorizerName,
    setAuthorizerEmail,
    setFilterMarkets,
    setIsOrgSelected,
    setSelectedOrg,
    formatNumberUS,
    properNaming,
    setProjectPriceSheets,
    setProjectSpaces,
    ProjectCalculator,
    user,
    isAdmin,
  } = useContext(PzPrimeContext);
  const navig = useNavigate();
  //   const [projectList, setProjectList] = useState(projectLists);
  const [itemCount, setItemCount] = useState(projectLoadCount);
  const [isBottom, setIsBottom] = useState(false);
  const loadCount = projectLoadCount; //Indicates the total number of projects loaded when scrolled to bottom
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      // Check if the user has scrolled to the bottom
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        setIsBottom(true);
      } else {
        setIsBottom(false);
      }
    };
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (isBottom) {
      if (projectList.length < itemCount + loadCount) {
        setItemCount(projectList.length);
      } else {
        setItemCount(itemCount + loadCount);
      }
      Logger.debug(
        "ProjectCollection",
        "Reached the bottom of the container!",
        itemCount
      );
    }
  }, [isBottom]);

  function formatDate(date, format) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear().toString().substring(2);

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    if (format === "month") {
      return [month, day, year].join("/");
    } else {
      return [d.getFullYear(), month, day].join("/");
    }
  }

  async function editProject(project, option) {
    const relation = [];
    // setProjectPriceSheets(project.priceSheet);
    ProjectCalculator(
      project.id,
      setProjectPriceSheets,
      setProjectSpaces,
      user
    );

    if (relation.length > 0) {
      const org = await DataStore.query(
        Organization,
        relation[0].organizationId
      );
      setIsOrgSelected(true);
      setSelectedOrg(org);
    }

    const constructionTypeConversion = {
      GROUND_UP: "New Construction",
      ADAPTIVE_REUSE_CLEAN_SLATE: "Adaptive Reuse/Clean Slate",
      ADAPTIVE_REUSE_RECYCLED_SYSTEMS: "Adaptive Reuse/Recycled System",
    };

    const tradeConversion = {
      MECH: "mechanical",
      ELEC: "electrical",
      PLUMB: "plumbing",
      GAS: "gas",
    };

    const marketConversion = {
      MULTIFAMILY_MIXED_USE: "Multifamily & Mixed Use",
      OFFICE_RETAIL: "Office and Retail",
      FOOD_BEVERAGE: "Food and Beverage",
      HEALTHCARE: "Healthcare",
      CORE_SHELL: "Core / Shell",
      WAREHOUSE_STORAGE: "Warehouse / Storage",
      SINGLE_FAMILY_RESIDENTIAL: "Single Family Residential",
      RELIGIOUS_CULTURAL: "Religious and Cultural",
      EDUCATION_DAYCARE: "Education and Daycare",
      FACTORY_INDUSTRIAL_GARAGE: "Factory | Industrial | Garage",
      THEATERS_EVENT_SPACES: "Theaters & Event Spaces",
      OTHER: "Other",
    };

    const packageLevelConversion = {
      MINIMALIST: "Minimalist",
      COLLABORATOR: "Collaborator",
      PERFECTIONIST: "Perfectionist",
    };

    for (let trade of project.tradeSupervisions)
      discipline[tradeConversion[trade]] = 1;

    let markets = [];
    for (const market of project.markets)
      markets.push(marketConversion[market]);

    setProjectId(project.id);
    setProjectName(project.name);
    setAddress(project.location.address);
    setLocation({
      lat: project.location.latitude,
      lng: project.location.longitude,
    });
    setDistanceMatrixData({
      distance: project.location.driveDistance,
      time: project.location.driveDuration,
    });
    setDiscipline(discipline);
    setConstructionType(constructionTypeConversion[project.constructionType]);
    setProjectArea(project.projectArea);
    setNumberOfBuildings(project.numberOfBuildings);
    setBuildingArea(project.totalBuildingsArea);
    setSelectedMarkets(markets);
    setFilterMarkets(markets);
    setSelectedPackage(packageLevelConversion[project.packageLevel]);
    setAuthorizerName(project.authorizerName);
    setAuthorizerEmail(project.authorizerEmail);
    setProjectSpaces(project.spaceDict);

    if (option !== "duplicate") {
      if (project.spaceDict && Object.keys(project.spaceDict).length > 0) {
        navig("/space-selector");
      } else {
        navig("/project-details");
      }
    } else {
      navig("/project-details");
    }
  }

  function deleteProject(project) {
    DataStore.delete(project);
  }

  async function duplicateSpaces(proID, spaceList) {
    for (const space of spaceList) {
      let spaceCopy = JSON.parse(JSON.stringify(space));

      let delKeys = ["createdAt", "updatedAt"];
      for (let key of delKeys) {
        delete spaceCopy[key];
      }

      spaceCopy["projectSpacesId"] = proID;

      await DataStore.save(new Space(spaceCopy))
        .then((res) => {
          Logger.debug("ProjectCollection", "Response:", res);
        })
        .catch((err) => Logger.error("Error:", err));
    }
    const currentProject = await DataStore.query(Project, proID);
    editProject(currentProject, "duplicate");
  }

  async function duplicateProject(project) {
    const spaceList = await DataStore.query(Space, (space) =>
      space.projectSpacesId.eq(project.id)
    );

    // const orgDetails = await DataStore.query(Organization, (org) =>
    //   org.projects.projectId.eq(project.id)
    // );

    let projectCopy = JSON.parse(JSON.stringify(project));

    projectCopy.name = "Copy of " + project.name;

    let delKeys = ["createdAt", "updatedAt"];

    for (let key of delKeys) {
      delete projectCopy[key];
    }

    await DataStore.save(new Project(projectCopy))
      .then((res) => {
        if (spaceList.length > 0) duplicateSpaces(res.id, spaceList);
        else editProject(res, "duplicate");
      })
      .catch((err) => Logger.error("Error:", err));
  }

  const [isEditing, setIsEditing] = useState(false);
  const [dealIDValue, setDealIDValue] = useState();
  const [dealEditIndex, setDealEditIndex] = useState();

  const onDealIDChange = (value) => {
    setDealIDValue(parseInt(value));
  };

  const onExit = async (value, project) => {
    if (value === "save") {
      await DataStore.save(
        Project.copyOf(project, (projectCopy) => {
          projectCopy.dealID = dealIDValue;
        })
      )
        .then((res) => Logger.debug("ProjectCollection", "Response:", res))
        .catch((err) => Logger.error("Error:", err));
    } else {
      setDealIDValue(project.dealID ? parseInt(project.dealID) : 0);
    }
    setIsEditing(false);
  };

  const Css = `
  .cardHover{
    border:1px solid #c5c5c5;
  }
  .cardHover:hover{
    background:#e9f9fc;
    border:2px solid #c5c5c5;
  }
  .addFont{
   color:#aeb3b7;
  }
  .cardHover:hover .addFont{
   color:grey;
  }
  .onhover{
    background:#c5c5c5;
    cursor:pointer
  }
  .onhover:hover{
    background:lightblue;
    transition:0.6s ease;
  }
  .inlineEdit .amplify-input{
    padding: 3px;
    width: 70px;
    min-width: 30px;
    box-shadow: none;
    border:1px solid #c5c5c5;
  }

    input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    input[type=number] {
    -moz-appearance: textfield;
    }
  .amplify-flex.amplify-field-group.amplify-field-group--has-inner-end.amplify-field-group--horizontal {
    border: 1px solid #c5c5c5;
    flex-direction:row-reverse;
    border-radius:2px;
  }
  button.amplify-button.amplify-field-group__control.amplify-field-group__icon.amplify-field-group__icon-button.amplify-searchfield__search {
    border-radius:0;
    border:none;
  }

  .amplify-field-group--has-inner-end .amplify-input {
    border:none;
    padding-left: 0px;
  }
  .amplify-field-group--has-inner-end .amplify-input:focus {
    border:none;
    box-shadow:none;
  }
  .amplify-searchfield__search:focus ,.amplify-searchfield__search:active, .amplify-searchfield__search:hover {
   background-color: transparent;
  border-color: none;
  box-shadow: none;
  }
  .amplify-searchfield{
    margin-top:-10px;
    border-radius:2px;
    width:100%;
    background: #fff;
  }
  .amplify-flex.amplify-collection-search {
    position: sticky;
    top: 0px;
    width: 100%;
}
  `;

  const darkCss = `
  .cardHover:hover{
    background:#00404d;
    border:2px solid #c5c5c5;
  }
  .addFont{
    color:#aeb3b7;
   }
   .cardHover:hover .addFont{
    color:#fff;
   }
   .amplify-searchfield{
    background: #191f21;
  }
  `;

  return (
    <>
      <style>{colorMode === "dark" ? Css + darkCss : Css}</style>
      <Flex
        direction={"column"}
        padding={"10px"}
        width={"100%"}
        ref={containerRef}
        position={"relative"}
      >
        <Collection
          marginTop={"10px"}
          type="list"
          items={projectList}
          width={"100%"}
          isSearchable
          searchPlaceholder=" Search by Project Name, Location, Construction Type..."
          searchNoResultsFound={
            <Flex justifyContent="center">
              <Text fontSize="20px" marginTop={"20px"}>
                No projects found
              </Text>
            </Flex>
          }
          alignItems={"center"}
        >
          {(project, index) => {
            if (index < itemCount)
              return (
                <Card
                  key={index}
                  display={"flex"}
                  direction={{
                    base: "column",
                    small: "column",
                    medium: "column",
                    large: "row",
                    xl: "row",
                    xxl: "row",
                  }}
                  borderRadius="10px"
                  border="1px solid #A4A4A4"
                  boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
                  minHeight={"250px"}
                  justifyContent="space-between"
                  alignItems="stretch"
                  width={{
                    base: "100%",
                    small: "500px",
                    medium: "500px",
                    large: "100%",
                    xl: "100%",
                    xxl: "100%",
                  }}
                  gap="10px"
                >
                  <Flex
                    direction="row"
                    borderRadius="8px"
                    backgroundColor="buttonface"
                    minWidth={"250px"}
                    width={"100%"}
                    maxWidth={{
                      medium: "100%",
                      large: "300px",
                      xl: "300px",
                      xxl: "380px",
                    }}
                    padding={"15px"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Flex
                      width={"100%"}
                      height={"100%"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      direction={"column"}
                      gap={"5px"}
                    >
                      <Text width={"100%"}>
                        Edited: {formatDate(project.updatedAt, "month")}
                      </Text>
                      <Image
                        alt="PermitZip logo"
                        src={require("../Assets/permitZIP.png")}
                        height="115px"
                        width="115px"
                        padding={"5px"}
                        backgroundColor={"#fff"}
                        borderRadius={"50%"}
                        border={"1px solid #aeb3b7"}
                        cursor={
                          "--amplify-components-autocomplete-menu-option-cursor"
                        }
                        className="pointerEvents"
                      />
                      <Text
                        className={"cursorProjectCollection pointerEvents"}
                        margin={"10px"}
                        maxWidth={"250px"}
                        textAlign={"center"}
                        fontSize="25px"
                        fontStyle="normal"
                        fontWeight="400"
                        lineHeight="130%"
                      >
                        {project.name}
                      </Text>

                      {isAdmin && (
                        <View
                          display={"flex"}
                          gap="3px"
                          // width={'100%'}
                          minWidth={"100px"}
                          alignItems="center"
                          className="inlineEdit"
                        >
                          <Text margin={"0 3px"}>Deal ID</Text>
                          {isEditing && index === dealEditIndex ? (
                            <View position={"relative"}>
                              <Icon
                                position={"absolute"}
                                right={"20px"}
                                top={"-17px"}
                                className="cursorProjectCollection onhover"
                                borderRadius={"50%"}
                                padding={"2px"}
                                fontSize={"18px"}
                                textAlign={"center"}
                                onClick={() => onExit("cancel", project)}
                                id="cancel-btn"
                              >
                                <svg
                                  width="26"
                                  height="26"
                                  viewBox="0 0 24 24"
                                  fill="red"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                                </svg>
                              </Icon>
                              <Icon
                                className="cursorProjectCollection onhover"
                                position={"absolute"}
                                right={"0px"}
                                top={"-17px"}
                                borderRadius={"50%"}
                                padding={"2px"}
                                fontSize={"18px"}
                                onClick={() => onExit("save", project)}
                                id="save-btn"
                              >
                                <svg
                                  width="26"
                                  height="26"
                                  viewBox="0 0 24 24"
                                  fill="green"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M9.00016 16.1698L4.83016 11.9998L3.41016 13.4098L9.00016 18.9998L21.0002 6.99984L19.5902 5.58984L9.00016 16.1698Z" />
                                </svg>
                              </Icon>

                              <TextField
                                placeholder=" 0"
                                value={dealIDValue}
                                labelHidden
                                onFocus={(e) => e.target.select()}
                                type="number"
                                maxWidth={"70px"}
                                onChange={(e) => onDealIDChange(e.target.value)}
                              />
                            </View>
                          ) : (
                            <Text
                              minWidth={"45px"}
                              width={"max-content"}
                              textAlign={"right"}
                              fontSize={"17px"}
                            >
                              {project.dealID ? project.dealID : "----"}
                            </Text>
                          )}

                          <Icon
                            marginLeft={"3px"}
                            className="cursorProjectCollection onhover"
                            borderRadius={"50%"}
                            padding={"2px"}
                            display={
                              isEditing && dealEditIndex === index
                                ? "none"
                                : "block"
                            }
                            onClick={() => {
                              setIsEditing(true);
                              setDealIDValue(project.dealID);
                              setDealEditIndex(index);
                            }}
                            fontSize={"20px"}
                          >
                            <svg
                              width="26"
                              height="26"
                              viewBox="0 0 24 24"
                              fill="#545352"
                              xmlns="http://www.w3.org/2000/svg"
                              className="onhover"
                            >
                              <path d="M3 17.2505V21.0005H6.75L17.81 9.94049L14.06 6.19049L3 17.2505ZM20.71 5.63049L18.37 3.29049C18.2775 3.19779 18.1676 3.12424 18.0466 3.07406C17.9257 3.02388 17.796 2.99805 17.665 2.99805C17.534 2.99805 17.4043 3.02388 17.2834 3.07406C17.1624 3.12424 17.0525 3.19779 16.96 3.29049L15.13 5.12049L18.88 8.87049L20.71 7.04049C20.8027 6.94798 20.8762 6.83809 20.9264 6.71712C20.9766 6.59614 21.0024 6.46646 21.0024 6.33549C21.0024 6.20452 20.9766 6.07484 20.9264 5.95387C20.8762 5.83289 20.8027 5.723 20.71 5.63049Z" />
                            </svg>
                          </Icon>
                        </View>
                      )}
                    </Flex>
                  </Flex>
                  <Flex
                    direction={"column"}
                    padding={"10px"}
                    borderRadius={"10px"}
                    width={"100%"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                  >
                    <Heading>Project Info</Heading>
                    <Flex
                      direction={"column"}
                      gap={"5px"}
                      width={"auto"}
                      padding={"8px 15px"}
                      borderRadius={"10px"}
                      maxWidth={"900px"}
                      minWidth={{
                        medium: "250px",
                        large: "300px",
                        xl: "400px",
                      }}
                      justifyContent={"space-between"}
                      alignItems={"space-between"}
                      backgroundColor={"ButtonFace"}
                    >
                      <Text
                        width={"100%"}
                        textAlign={"center"}
                        fontSize={"120%"}
                        fontWeight={"bold"}
                      >
                        {project.priceSheet.total ? (
                          `$ ${formatNumberUS(project.priceSheet.total, 2)}`
                        ) : (
                          <Badge variation="error">
                            Project Setup Incomplete
                          </Badge>
                        )}
                      </Text>
                      <Text width={"100%"}>
                        Promo Code:{" "}
                        {project.priceSheet.promoDiscounts &&
                        project.priceSheet.promoDiscounts.length > 0
                          ? `${project.priceSheet.promoDiscounts[0].code}
                    (-$${formatNumberUS(
                      project.priceSheet.promoDiscounts[0].discount,
                      2
                    )})`
                          : "N/A"}
                      </Text>
                      <Text width={"100%"}>
                        Project Size:{" "}
                        {project.projectArea
                          ? `${formatNumberUS(project.projectArea, 0)} SF`
                          : "Not Selected"}
                      </Text>

                      <Text width={"100%"}>
                        Location:{" "}
                        {project.location.address
                          ? project.location.address
                          : "No location was added"}
                      </Text>
                      <Text width={"100%"}>
                        Created On: {formatDate(project.createdAt)}
                      </Text>
                      <Text>
                        Selected Markets:
                        <span style={{ margin: "0 8px" }}>
                          {project.markets && project.markets.length !== 0
                            ? project.markets
                                .map((item, index) => properNaming(item))
                                .join(", ")
                            : "No markets were selected"}
                        </span>
                      </Text>
                      <Text>
                        Construction Type:
                        <span style={{ margin: "0 8px" }}>
                          {project.constructionType.length !== 0
                            ? properNaming(project.constructionType)
                            : "No construction type was selected"}
                        </span>
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex
                    direction={"column"}
                    padding={"10px"}
                    borderRadius={"10px"}
                    minWidth={"250px"}
                  >
                    <Heading textAlign={"center"}>Action</Heading>
                    <Flex
                      wrap={"wrap"}
                      justifyContent="center"
                      alignItems={"center"}
                      direction={"column"}
                    >
                      {loadPage === "proposalEditor" && (
                        <Button
                          borderRadius={"25px"}
                          variation="primary"
                          onClick={() => {
                            optionalFunction(project);
                          }}
                          minWidth={"150px"}
                        >
                          <Text color={"#f4f4f4"}>
                            {isProjectLink ? "Link Project" : "Load Data"}
                          </Text>
                        </Button>
                      )}
                      {loadPage !== "promoCodeForm" &&
                        loadPage !== "proposalEditor" &&
                        project.proposals &&
                        Object.keys(project.proposals).length > 0 && (
                          <Button
                            variation="primary"
                            borderRadius={"25px"}
                            minWidth={"150px"}
                            onClick={() => {
                              optionalFunction(project?.proposals);
                            }}
                          >
                            <Flex
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                              gap={"3px"}
                              width={"100%"}
                            >
                              <Icon
                                fontSize={"34px"}
                                borderRadius={"20px"}
                                padding={"3px"}
                              >
                                <svg
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9 17H15M9 13H15M9 9H10M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H15.8C16.9201 21 17.4802 21 17.908 20.782C18.2843 20.5903 18.5903 20.2843 18.782 19.908C19 19.4802 19 18.9201 19 17.8V9M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19"
                                    stroke="#000000"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </Icon>
                              <Text color={"#f4f4f4"}>View Proposals</Text>
                            </Flex>
                          </Button>
                        )}
                      {loadPage !== "proposalEditor" && (
                        <Button
                          borderRadius={"25px"}
                          variation="primary"
                          onClick={() => {
                            editProject(project);
                          }}
                          minWidth={"150px"}
                        >
                          <Flex
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            gap={"3px"}
                            width={"100%"}
                          >
                            <Image
                              src={require("../Assets/edit.png")}
                              padding={"3px"}
                              height={"32px"}
                              borderRadius={"20px"}
                            />
                            <Text color={"#f4f4f4"}>Edit</Text>
                          </Flex>
                        </Button>
                      )}
                      {loadPage !== "promoCodeForm" &&
                        loadPage !== "proposalEditor" && (
                          <Button
                            variation="primary"
                            borderRadius={"25px"}
                            minWidth={"150px"}
                            onClick={() => {
                              duplicateProject(project);
                            }}
                          >
                            <Flex
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                              gap={"3px"}
                              width={"100%"}
                            >
                              <Icon
                                fontSize={"34px"}
                                borderRadius={"20px"}
                                padding={"3px"}
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect width="24" height="24" />
                                  <path
                                    d="M16 8V6C16 5.46957 15.7893 4.96086 15.4142 4.58579C15.0391 4.21071 14.5304 4 14 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V14C4 14.5304 4.21071 15.0391 4.58579 15.4142C4.96086 15.7893 5.46957 16 6 16H8M8 10C8 9.46957 8.21071 8.96086 8.58579 8.58579C8.96086 8.21071 9.46957 8 10 8H18C18.5304 8 19.0391 8.21071 19.4142 8.58579C19.7893 8.96086 20 9.46957 20 10V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20H10C9.46957 20 8.96086 19.7893 8.58579 19.4142C8.21071 19.0391 8 18.5304 8 18V10Z"
                                    stroke="#000"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <rect
                                    x="-285.5"
                                    y="-382.5"
                                    width="524"
                                    height="817"
                                    rx="4.5"
                                    strokeDasharray="10 5"
                                  />
                                </svg>
                              </Icon>
                              <Text color={"#f4f4f4"}>Duplicate</Text>
                            </Flex>
                          </Button>
                        )}
                      {loadPage !== "promoCodeForm" &&
                        loadPage !== "proposalEditor" && (
                          <Button
                            variation="destructive"
                            borderRadius={"25px"}
                            minWidth={"150px"}
                            onClick={() => deleteProject(project)}
                          >
                            <Flex
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                              gap={"3px"}
                              width={"100%"}
                            >
                              <Image
                                src={require("../Assets/delete.png")}
                                height={"32px"}
                                padding={"3px"}
                                borderRadius={"20px"}
                              />
                              <Text color={"#f4f4f4"}>Delete</Text>
                            </Flex>
                          </Button>
                        )}
                    </Flex>
                  </Flex>
                </Card>
              );
          }}
        </Collection>
      </Flex>
    </>
  );
};

export default ProjectCollection;
