import { DataStore } from "aws-amplify";
import {
  SpaceTemplate,
  Space,
  Project,
  PricingModel,
  PriceSheet,
  BasePrice,
} from "../models";
import Logger from "../utils/Logger";

const ProjectCalculator = (
  projectId,
  setProjectPriceSheets,
  setProjectSpaces,
  user,
  spaceRecord,
  trades
) => {
  async function getSpaceRecord(spaceID) {
    let spaceRecord = await DataStore.query(Space, (space) =>
      space.id.eq(spaceID)
    );
    return spaceRecord[0];
  }

  // Calculate Project cost based on calculating each Space, then calculate Department subtotals
  async function calculateProject(
    project,
    setProjectPriceSheets,
    setProjectSpaces
  ) {
    // Duplicate project metadata for calculation
    let currentProject = JSON.parse(JSON.stringify(project));

    // Debug input:
    Logger.debug(
      "ProjectCalculator",
      "Starting Calculation for project",
      currentProject.name
    );

    let projectPriceSheet = {};

    let basePrice = await DataStore.query(BasePrice);

    if (basePrice.length > 0) {
      basePrice.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
      let trades = ["mech", "elec", "plum"];
      for (let trade of trades) {
        projectPriceSheet[trade] = [
          basePrice[0]["disciplines"][trade]["setUpFee"],
          basePrice[0]["disciplines"][trade]["costPerSF"],
        ];
      }
    }

    let uniqueSpaces = [];
    let isFirstInstance = false;

    const useContextTradeConversion = {
      MECH: "mech",
      ELEC: "elec",
      PLUMB: "plum",
    };

    let departments = currentProject.tradeSupervisions.map(
      (department) => useContextTradeConversion[department]
    );

    // Initialize setup fees
    for (const department of departments) {
      projectPriceSheet[department + "Total"] =
        projectPriceSheet[department][0];
    }

    // Check if the spaceDict field is empty for the project
    // If yes, convert the spaces table record (list of object) to a spaceDict field format (nested Object)
    if (
      !currentProject.spaceDict ||
      Object.keys(currentProject.spaceDict).length === 0
    ) {
      // Calculate all spaces and iterate project department costs - should also update Space data records through calculateSpace function
      const legacyProjectSpaces = await project.spaces.toArray();
      currentProject.spaceDict = buildSpaceDict(legacyProjectSpaces);
    }

    for (let space in currentProject.spaceDict) {
      // Duplicate space metadata for calculation
      let spaceCopy = JSON.parse(
        JSON.stringify(currentProject.spaceDict[space])
      );

      // Determine if this is first instance of Space type on Project
      if (uniqueSpaces.includes(spaceCopy.templateID)) {
        isFirstInstance = false;
      } else {
        isFirstInstance = true;
        uniqueSpaces.push(spaceCopy.templateID);
      }

      // TODO: Debug Input
      Logger.debug("ProjectCalculator", "Calculating", spaceCopy.customName);

      calculateSpace(
        projectPriceSheet,
        spaceCopy,
        departments,
        isFirstInstance
      );

      // For each department, add subtotals to Project costs
      for (const department of departments) {
        projectPriceSheet[department + "Total"] +=
          spaceCopy.priceSheet[department + "Total"];
      }

      currentProject.spaceDict[space] = spaceCopy;
    }

    // Initialize sub-total project cost
    projectPriceSheet["initialSubtotal"] = 0;

    // For each department, add to sub-total cost
    for (const department of departments) {
      projectPriceSheet["initialSubtotal"] +=
        projectPriceSheet[department + "Total"];
    }

    Logger.debug(
      "ProjectCalculator",
      "Initial sub total",
      projectPriceSheet["initialSubtotal"]
    );

    // Check for promocodes and add discount
    runPromos(currentProject, projectPriceSheet);

    Logger.debug(
      "ProjectCalculator",
      "Sub total after applying promocodes",
      projectPriceSheet["subtotalAfterPromos"]
    );

    projectPriceSheet["taxes"] =
      Math.round(
        basePrice[0]["taxRate"] * projectPriceSheet["subtotalAfterPromos"] * 100
      ) / 100;

    Logger.debug(
      "ProjectCalculator",
      "Calculated tax",
      projectPriceSheet["taxes"]
    );

    projectPriceSheet["total"] =
      projectPriceSheet["subtotalAfterPromos"] + projectPriceSheet["taxes"];

    // Update Project record to DataStore
    await DataStore.save(
      Project.copyOf(project, (projectCopy) => {
        projectCopy.priceSheet = projectPriceSheet;
        projectCopy.spaceDict = currentProject.spaceDict;
      })
    )
      .then((res) => {
        setProjectSpaces(JSON.parse(JSON.stringify(res.spaceDict)));
      })
      .catch((err) =>
        Logger.error("ProjectCalculator", "Failed to save project:", err)
      );

    setProjectPriceSheets(projectPriceSheet);

    // TODO: Debug output
    Logger.debug(
      "ProjectCalculator",
      "Calculated Project pricesheet for",
      currentProject.name
    );
    Logger.debug("ProjectCalculator", "Project pricesheet:", projectPriceSheet);
    return projectPriceSheet;
  }

  function runPromos(currentProject, projectPriceSheet) {
    projectPriceSheet["promoDiscounts"] = [];
    projectPriceSheet["subtotalAfterPromos"] =
      projectPriceSheet["initialSubtotal"];
    for (const promo of currentProject.promoCodes) {
      if (
        projectPriceSheet["initialSubtotal"] >=
          promo.requirements.minimumSubtotal &&
        (promo.requirements.requireSignin === false || user !== "")
      ) {
        let discount = 0;
        if (promo.promoClass === "PERCENTAGE_DISCOUNT")
          discount =
            Math.round(projectPriceSheet["initialSubtotal"] * promo.discount) /
            100;
        else discount = promo.discount;
        projectPriceSheet["promoDiscounts"].push({
          code: promo.code,
          discount: discount,
        });
        projectPriceSheet["subtotalAfterPromos"] -= discount;
      }
    }
  }

  function buildSpaceDict(spaces) {
    let spaceDict = {};
    for (const space of spaces) {
      if (space.className !== "Project Cost Flags") {
        spaceDict[space.customName] = space;
      }
    }
    return spaceDict;
  }

  // Calculate the total cost of a Space
  // Return totals and department subtotals for one space and all attached Areas
  // projectBaseFees is the same as project.priceSheet, and should have a key for each department with a matching list of Floats. first Float is project/department setup fee, second is $/SF
  function calculateSpace(
    projectPriceSheet,
    space,
    departments,
    isFirstInstance = false
  ) {
    space.priceSheet.total = 0;

    // For each department, get the department costs and sort data into the spaceCosts and newAreas objects
    for (const department of departments) {
      // TODO: Debug Input
      Logger.debug(
        "ProjectCalculator",
        "Calculating Space for",
        space.customName,
        "for",
        department
      );

      // Retrieve/Sanitize data
      let departmentBaseFee = projectPriceSheet[department][1];

      // Calculate departmental cost for all areas in Space
      let spaceDepartmentCost = calculateSpaceDepartment(
        space,
        department,
        departmentBaseFee,
        isFirstInstance
      );

      // Write calculations for department to Space PriceSheet
      space.priceSheet[department + "Total"] = spaceDepartmentCost;

      // Sum total for Space PriceSheet
      space.priceSheet.total += spaceDepartmentCost;
    }

    // Debug output
    Logger.debug("ProjectCalculator", "Calculated Space:", space.customName);
    Logger.debug("ProjectCalculator", "Space details:", space);
    return space;
  }

  // Calculate Space subtotal, and each Area subtotal, for ONE department
  // Return ONE department's subtotals for ONE space and each area
  // Cannot operate on Space record directly from database - need to create deep copy
  function calculateSpaceDepartment(
    space,
    department,
    departmentBaseFee,
    isFirstInstance = false
  ) {
    // Debug input:
    Logger.debug(
      "ProjectCalculator",
      "Calculating",
      department,
      "costs for space:",
      space.customName
    );
    Logger.debug("ProjectCalculator", "Space input:", space);

    // Initialize departmental cost
    let spaceDepartmentCost;

    // Add setup fee to cost
    if (isFirstInstance) {
      spaceDepartmentCost = space.priceSheet[department][0];
    } else {
      spaceDepartmentCost = space.priceSheet[department][1];
      // ^ This is why it's very important we give default [0, 0] to each department in Space Template editor (front end)
    }

    // Add each area fee to cost
    for (const area of space.areas) {
      // Debug input:
      Logger.debug(
        "ProjectCalculator",
        "Calculating",
        area.areaTitle,
        "costs for",
        department,
        "of space:",
        space.customName
      );

      // Calculate departmental cost for area
      let areaDepartmentCost = calculateAreaDepartment(
        area,
        department,
        departmentBaseFee
      );
      area.priceSheet[department + "Total"] = areaDepartmentCost;
      area.priceSheet.total = 0;
      if (area.priceSheet.hasOwnProperty("mechTotal"))
        area.priceSheet.total += area.priceSheet.mechTotal;
      if (area.priceSheet.hasOwnProperty("elecTotal"))
        area.priceSheet.total += area.priceSheet.elecTotal;
      if (area.priceSheet.hasOwnProperty("plumTotal"))
        area.priceSheet.total += area.priceSheet.plumTotal;
      spaceDepartmentCost += areaDepartmentCost;

      // Debug output
      Logger.debug(
        "ProjectCalculator",
        "Calculated",
        area.areaTitle,
        "costs for",
        department,
        "of space",
        space.customName,
        ":",
        areaDepartmentCost
      );
    }

    // Debug output
    Logger.debug(
      "ProjectCalculator",
      "Calculated",
      department,
      "costs for",
      space.customName,
      ":",
      spaceDepartmentCost
    );

    // Round the spaceDepartmentCost up
    let roundingFactor = 1;
    spaceDepartmentCost /= roundingFactor;
    spaceDepartmentCost = Math.ceil(spaceDepartmentCost);
    spaceDepartmentCost *= roundingFactor;

    // Return cost for space/department
    return spaceDepartmentCost;
  }

  // Calculate a single department's cost within an Area
  function calculateAreaDepartment(area, department, departmentBaseFee) {
    // Initialize departmental cost
    let areaDepartmentCost;

    // Calculate area cost based on SF and pricing model

    if (area.priceSheet.pricingModel === "RELATIVE_LINEAR") {
      //TODO: Dividing by 100 to adjust incorrect percetage input. Remove divider after fixing input
      let areaDepartmentModifier = area.priceSheet[department][0] / 100;
      areaDepartmentCost =
        area.area * departmentBaseFee * areaDepartmentModifier; //TODO: Round to the nearest 0.01
    } else if (area.priceSheet.pricingModel === "COMPRESSED") {
      let [initialModifier, discountModifier, discountSF, totalCheck] =
        area.priceSheet[department];
      //TODO: Dividing by 100 to adjust incorrect percetage input. Remove divider after fixing input
      let initialRate = (departmentBaseFee * initialModifier) / 100;
      let discountRate = (departmentBaseFee * discountModifier) / 100;
      let c = 1;
      if (discountSF > 0) c = 5.3 / discountSF; //-ln(0.005)=Approx 5.3
      areaDepartmentCost =
        area.area *
        (discountRate +
          (initialRate - discountRate) * Math.exp(-c * area.area));
    }

    // Return calculated cost
    return areaDepartmentCost;
  }

  async function testCalculateAreaDepartment(
    spaceID,
    department,
    departmentBaseFee
  ) {
    // Set up area input for test
    let spaceRecord = getSpaceRecord();
    let area = spaceRecord.areas[0];

    // Test functionality
    let areaDepartmentCost = calculateAreaDepartment(
      area,
      department,
      departmentBaseFee
    );

    return areaDepartmentCost;
  }

  async function testCalculateSpaceDepartment(
    spaceID,
    department,
    departmentBaseFee,
    isFirstInstance
  ) {
    let spaceRecord = await DataStore.query(Space, (space) =>
      space.id.eq(spaceID)
    );
    let space = JSON.parse(JSON.stringify(spaceRecord[0])); //Create a Copy of the Space Record
    let spaceDepartmentCosts = calculateSpaceDepartment(
      space,
      department,
      departmentBaseFee,
      isFirstInstance
    );
  }

  async function testCalculateSpace(spaceID, isFirstInstance = false) {
    // Set base fees if not provided
    let projectBaseFees = {};
    // let baseFeesRecords = await DataStore.query(DisciplineBasePrice);
    const baseFeesTradeConversion = {
      MECH: "mech",
      ELEC: "elec",
      PLUMB: "plum",
    };
    // for (const department of baseFeesRecords) {
    //     projectBaseFees[baseFeesTradeConversion[department.discipline]] = [department.setUpFee, department.costPerSF]
    // }

    // Set departments from useContext if not provided
    let departments = [];
    const useContextTradeConversion = {
      mechanical: "mech",
      electrical: "elec",
      plumbing: "plum",
    };
    // for (const department in discipline) {
    //     if (discipline[department] === 1)
    //         departments.push(useContextTradeConversion[department])
    // }

    // Retrieve space record for test
    let spaceRecord = await DataStore.query(Space, (space) =>
      space.id.eq(spaceID)
    );

    //Create a Copy of the Space Record for manipulation
    let space = JSON.parse(JSON.stringify(spaceRecord[0]));

    // Run test
    let spaceDepartmentCosts = calculateSpace(
      projectBaseFees,
      space,
      departments,
      isFirstInstance
    );
  }

  async function testCalculateProject(
    projectId,
    setProjectPriceSheets,
    setProjectSpaces
  ) {
    // Get project record by ID in context
    const project = await DataStore.query(Project, projectId);

    // Calculate project (test)
    calculateProject(project, setProjectPriceSheets, setProjectSpaces);
  }

  // testCalculateSpaceDepartment('ee6d779e-dc35-47c7-907a-19e810a03b3f', 'mech', 3, true)

  // testCalculateSpace('ee6d779e-dc35-47c7-907a-19e810a03b3f', true)

  // testCalculateProject(projectId, setProjectPriceSheets, setProjectSpaces)

  async function functionSanitizer(
    projectId,
    spaceRecord,
    trades,
    setProjectPriceSheets,
    setProjectSpaces
  ) {
    if (!projectId) {
      let departments = trades;
      let projectPriceSheet = {
        mech: [0, 0],
        elec: [0, 0],
        plum: [0, 0],
        mechTotal: 0,
        elecTotal: 0,
        plumTotal: 0,
      };
      let baseFeesRecords = await DataStore.query(BasePrice);

      if (baseFeesRecords.length > 0) {
        baseFeesRecords.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });
        let trades = ["mech", "elec", "plum"];
        for (let trade of trades) {
          projectPriceSheet[trade] = [
            0,
            baseFeesRecords[0]["disciplines"][trade]["costPerSF"],
          ];
          projectPriceSheet[trade + "Total"] = 0;
        }
      }

      let spaceCopy = JSON.parse(JSON.stringify(spaceRecord));

      return calculateSpace(projectPriceSheet, spaceCopy, departments, true);
    } else {
      // Get project record by ID in context
      const project = await DataStore.query(Project, projectId);

      // Calculate project (test)
      calculateProject(project, setProjectPriceSheets, setProjectSpaces);
      return "";
    }
  }

  return functionSanitizer(
    projectId,
    spaceRecord,
    trades,
    setProjectPriceSheets,
    setProjectSpaces
  );
};

export default ProjectCalculator;
